// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-article-js": () => import("./../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-purpose-js": () => import("./../../src/pages/purpose.js" /* webpackChunkName: "component---src-pages-purpose-js" */),
  "component---src-pages-studies-js": () => import("./../../src/pages/studies.js" /* webpackChunkName: "component---src-pages-studies-js" */),
  "component---src-pages-summary-js": () => import("./../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-vision-js": () => import("./../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

